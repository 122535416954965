import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  getPickupGroups(): Observable<object> {
    return this.httpClient.get(`/pickup/get-groups`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getPickupDisplay(
    date,
    page = 1,
    pageSize = 50,
    statusFilter
  ): Observable<object> {
    return this.httpClient
      .get(
        `/pickup/display?date=${date}&page=${page}&page-size=${pageSize}&status-filter=${statusFilter}`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPickupStudentOnVisible(
    group_id,
    page = 1,
    pageSize = 50
  ): Observable<object> {
    return this.httpClient
      .get(
        `/pickup/student-on-visible?group-id=${group_id}&page=${page}&page-size=${pageSize}`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  returnStudentOutOfClass(studentId): Observable<object> {
    let listStudentId = [studentId];
    return this.httpClient
      .post(`/pickup/return-student-out-class`, {
        listStudentId,
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  setAbsentForStudent(studentId): Observable<object> {
    return this.httpClient
      .post(`/pickup/create-attendance-note`, {
        studentId: studentId,
        type: 2,
        note: "SET ABSENT FROM WEB PICKUP",
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  // postProtectorCheck
  postProtectorCheck(studentId, protectorCheck): Observable<object> {
    return this.httpClient
      .post(`/pickup/update-protector-note`, {
        studentId,
        protectorCheck,
      })
      .pipe(
        map((response: any[]) => {
          return response;
        })
      );
  }

  postUpdateVisibleStatus(visible, groupId, studentId): Observable<object> {
    return this.httpClient
      .post(`/pickup/update-visible-status`, {
        visible,
        groupId,
        studentId,
      })
      .pipe(
        map((response: any[]) => {
          return response;
        })
      );
  }

  searchStudent(searchTerm): Observable<object> {
    return this.httpClient
      .get(`/pickup/search-student?search=${searchTerm}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  postAddStudent(parentId, students): Observable<object> {
    return this.httpClient
      .post(`/pickup/parent-checkin-school`, {
        parentId,
        students,
      })
      .pipe(
        map((response: any[]) => {
          return response;
        })
      );
  }
  pickupStudentImmediately(studentId): Observable<object> {
    return this.httpClient
      .post(`/pickup/pick-student`, {
        studentId,
      })
      .pipe(
        map((response: any[]) => {
          return response;
        })
      );
  }

  getApiCards(): Observable<object> {
    return this.httpClient.get(`/pickup/temporary-uid-card`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getApiCardParents(): Observable<object> {
    return this.httpClient.get(`/pickup/list-parent`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getApiCardDetail(id: number): Observable<object> {
    return this.httpClient.get(`/pickup/temporary-uid-card/${id}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  postAddCrads(payload: any): Observable<object> {
    return this.httpClient
      .post(`/pickup/temporary-uid-card`, {
        ...payload,
      })
      .pipe(
        map((response: any[]) => {
          return response;
        })
      );
  }

  updateCrads(payload: any, id: number): Observable<object> {
    return this.httpClient
      .put(`/pickup/temporary-uid-card/${id}`, {
        ...payload,
      })
      .pipe(
        map((response: any[]) => {
          return response;
        })
      );
  }
}
