import { PickupStudentComponent } from './screens/pickup-student/pickup-student.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuard } from './core/guards/auth.guard';
import { PageNotFoundComponent } from './screens/page-not-found/page-not-found.component';
import { AuthGuard } from './core/guards/auth.guard';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./screens/public/public.route').then(m => m.PublicRoutingModule)
  },
  {
    path: '',
    loadChildren: () => import('./screens/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
