import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { HttpClient,  } from "@angular/common/http";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ErrorDialogComponent } from "../../components/error-login/error-login.component";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  token = "";

  constructor(
    private cookie: CookieService,
    private router: Router,
    private httpClient: HttpClient,
    public dialog: MatDialog
  ) {}

  public isAuthenticated(): boolean {
    let jwtToken: any = this.cookie.get("jwtToken");

    if (jwtToken && jwtToken !== "") {
      try {
        jwtToken = JSON.parse(jwtToken);
      } catch {
        return false;
      }
      this.token = jwtToken.token;

      const current = Math.floor(new Date().getTime() / 1000);

      if (jwtToken.exp < current) {
        this.cookie.set("jwtToken", "");
        return false;
      }

      return true;
    }

    return false;
  }

  login(loginContext: { username: string; password: string }) {
    this.httpClient.post("/auth/admin/login", loginContext).subscribe(
      // nếu đăng nhập thành công thì lưu token vào cookie và chuyển hướng về trang home không thì hiển thị alert
      (response: any) => {
        this.cookie.set("jwtToken", JSON.stringify(response.data?.token));
        if (response.code === "SUCCESS") {
          this.router.navigate([""]);
        } else {
          this.openErrorDialog();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  logout() {
    // xoá cookie jwtToken khi logout và chuyển hướng về trang login
    this.cookie.set("jwtToken", "");
    // xoá assest token khi logout
    this.token = "";
    this.router.navigate(["login"]);
  }

  getTokenString() {
    return this.token;
  }
  openErrorDialog(): void {
    const dialogRef = this.dialog.open(ErrorDialogComponent);

    dialogRef.afterClosed().subscribe(() => {
      console.log("The dialog was closed");
    });
  }
}
