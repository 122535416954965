<div class="error-dialog">
    		<div class="screen">

    <h2>Error</h2>
    <p>
        Your username or password is incorrect. Please try again.
    </p>
    <button mat-button (click)="closeDialog()">Try again</button>
   
  </div>
  