import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  listGroup: any;
  DEFAUL_GROUP = {
    code: '1',
    name: 'KHU VỰC 1',
  }

  selectedGroup: any;

  groups = [{
    code: '1',
    name: 'KHU VỰC 1',
  }, {
    code: '2',
    name: 'KHU VỰC 2',
  },
  {
    code: '3',
    name: 'KHU VỰC 3',
  }
];
  group = null;

  constructor(
    // private translateService: TranslateService,
    private auth: AuthService,
    private api: ApiService,
    private router: Router
  ) {
    let group = localStorage.getItem('group');
    if (group) {
      let usergroup = this.groups.filter(
        x => x.code == group
      );
      if (usergroup.length > 0) {
        this.selectedGroup = usergroup[0];
        // this.changeGroup(this.selectedGroup);
      }
    } else {
      this.selectedGroup = this.DEFAUL_GROUP;
      localStorage.setItem('group', this.selectedGroup.code);
    }
    this.getListGroup();
  }

  ngOnInit(): void {
  }

  navigateTo(component = '') {
    if (component && component !== '') {
      this.router.navigate([component]);
    }
  }

  navigateHome() {
      this.router.navigate(['/']);
  }

  onChangeGroup(event: any){
    console.log(event.value);
  }
  getListGroup(){
    this.api.getPickupGroups().subscribe(
      (data: any ) => {
        if(data) { 
          this.listGroup = data.data;
        }
      }, (e) => {
        console.log(e);
      }
    )
  }

  navigateGroup(group = '') {
    if (!group || group === '') {
      group = this.group;
    }
    if (group) {
      this.group = group;
      localStorage.setItem('group', group);
      this.router.navigate(['/groups', group]).then(() => {
        window.location.reload();
      });;
    }
  }

  // You can test it by binding it to .btn-online button, <<  (click)="logout()"  >>
  logout() {
    this.auth.logout();
  }

  get isValidated() {
    return this.auth.isAuthenticated();
  }
}
